import React, { useContext, useEffect, useRef, useState } from 'react';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import brightcovePlayerLoader from '@brightcove/player-loader';
import useTranslation from '../../useTranslation';
import localStyles from './CustomComponentContent.module.pcss';
import {
  addConsentButtonClickAction,
  getVideoConsentBannerElement
} from '../../common/CookieBanner/CookieConsentBannerHelper';
import { useClassNameMapper } from 'react-bootstrap';
import { getExternalVideoConsentCookie } from '../../common/CookieBanner/CookieHelper';
import TenantContext from '@aurora/shared-client/components/context/TenantContext';
import { getVideoUrlFromIframe } from '../../messages/VideoPreviewHelper/VideoPreviewHelper';
import { UPLOAD_STATUS } from '../../../helpers/editor/EditorVideoHelper';
import { getLog } from '@aurora/shared-utils/log';
import type { ComponentScriptGroups } from '@aurora/shared-generated/types/graphql-schema-types';
import dynamic from 'next/dynamic';

const CustomComponentScripts = dynamic(() => import('./CustomComponentScripts'));

interface Props {
  /**
   * The HTML markup.
   */
  markup: string;

  /**
   * The script groups.
   */
  scriptGroups?: ComponentScriptGroups;
}

const log = getLog(module);

const HtmlContent: React.FC<Props> = ({ markup, scriptGroups }) => {
  const cx = useClassNameMapper(localStyles);
  const widgetContentReference = useRef<HTMLDivElement>(null);
  const { formatMessage: textFormatMessage, loading: textLoading } = useTranslation(
    EndUserComponent.CUSTOM_COMPONENT_WIDGET,
    true
  );
  const {
    publicConfig: { showExternalVideoCookieBanner }
  } = useContext(TenantContext);
  const externalVideoConsentProvided = getExternalVideoConsentCookie();
  const showBanner = showExternalVideoCookieBanner && !externalVideoConsentProvided;
  const [videoElementsMap, setVideoElementsMap] = useState<Map<string, HTMLElement>>(new Map());

  /**
   * Load the brightcove player to render video when widget's element reference is available
   */
  useEffect(() => {
    const widgetContentElement = widgetContentReference.current;
    if (widgetContentElement) {
      const videoElements: NodeListOf<HTMLElement> =
        widgetContentElement.querySelectorAll('.lia-video-container');
      videoElements.forEach(videoElement => {
        const iframe = videoElement.querySelector('iframe');
        if (iframe && showBanner) {
          setVideoElementsMap(
            previousState =>
              new Map([...previousState, [videoElement.dataset.videoId, videoElement]])
          );
          const videoLink = `<a href="${getVideoUrlFromIframe(iframe?.src)}"
            class="${cx('lia-external-url')}"
            target="_blank" rel="noreferrer noopener">${textFormatMessage('urlText')}</a>`;
          const bannerElement = getVideoConsentBannerElement(
            cx,
            videoElement,
            iframe?.src,
            textFormatMessage('bannerTitle', { url: videoLink }),
            textFormatMessage('buttonTitle')
          );
          videoElement.replaceWith(bannerElement);
        } else {
          const referencedExternalId = videoElement.dataset.videoRemoteVid;
          const accountId = videoElement.dataset.account;
          const parentReferenceNode = videoElement?.querySelector(
            '.lia-video-display-wrapper'
          ) as HTMLElement;
          const referenceNode = parentReferenceNode?.querySelector('video-js');
          if (referenceNode && videoElement.dataset.liaVideoStatus !== UPLOAD_STATUS.LIVE) {
            videoElement.dataset.liaVideoStatus = UPLOAD_STATUS.LIVE;
            brightcovePlayerLoader({
              refNode: referenceNode,
              refNodeInsert: 'replace',
              accountId,
              playerId: 'default'
            })
              .then(success => {
                const player = success.ref;
                player.catalog.getVideo(referencedExternalId, (error, video) => {
                  if (error) {
                    player.error({ code: 'video-processing-error' });
                  } else {
                    player.catalog.load(video);
                  }
                });
                return true;
              })
              .catch(error => {
                log.error(error, 'Brightcove Player Error');
                throw new Error(error);
              });
          }
        }
      });
    }
  });

  if (textLoading) {
    return null;
  }

  if (showBanner) {
    addConsentButtonClickAction(widgetContentReference, cx, videoElementsMap);
  }

  return (
    <div className={cx('lia-content-wrap')} ref={widgetContentReference}>
      <div dangerouslySetInnerHTML={{ __html: markup }} />
      {scriptGroups && <CustomComponentScripts scriptGroups={scriptGroups} />}
    </div>
  );
};

export default HtmlContent;
